import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import BlogLayout from '../components/blog_layout'
import SEO from '../components/seo'


const SecondPage = () => {
    const data = useStaticQuery(graphql`
    {
      create_container: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/create-container.md/" } }) {
        nodes {
          html
        }
      }
      start_container: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/start-container.md/" } }) {
        nodes {
          html
        }
      }
      get_container: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/get-container.md/" } }) {
        nodes {
          html
        }
      }
      stop_container: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/stop-container.md/" } }) {
        nodes {
          html
        }
      }
      delete_container: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/delete-container.md/" } }) {
        nodes {
          html
        }
      }
      container_manager_reload: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/container-manager-reload.md/" } }) {
        nodes {
          html
        }
      }
      vagrantfile: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/vagrantfile.md/" } }) {
        nodes {
          html
        }
      }
      environment_setup: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/environment-setup.md/" } }) {
        nodes {
          html
        }
      }
      daemon_run: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/daemon-run.md/" } }) {
        nodes {
          html
        }
      }
      client_run: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/client-run.md/" } }) {
        nodes {
          html
        }
      }
      daemon_hi: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/daemon-hi.md/" } }) {
        nodes {
          html
        }
      }
      daemon_bye: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/daemon-bye.md/" } }) {
        nodes {
          html
        }
      }
      client_status_one: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/client-status-one.md/" } }) {
        nodes {
          html
        }
      }
      client_status_two: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/client-status-two.md/" } }) {
        nodes {
          html
        }
      }
      container_cleanup: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/container-cleanup.md/" } }) {
        nodes {
          html
        }
      }
      container_list: allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/container-list.md/" } }) {
        nodes {
          html
        }
      }
    }
  `)

    return (
      <BlogLayout>
        <SEO title="Rusty container manager" />
        <h1>Implementing a container manager in rust.</h1>

        <div style={{display: "flex", margin: "auto"}}>
            <div style={{marginLeft: "10%", marginRight: "5%"}}>
                <img src="https://quip.com/blob/cBTAAAbA9qM/bn99qkt6c6ADwqHZXwjCrQ"/>
            </div>
            <div style={{marginRight: "10%", marginLeft: "5%"}}>
                <img src="https://quip.com/blob/cBTAAAbA9qM/Usas35XZkNzjd9hUztZlsA"/>
            </div>
        </div>

        <p>
            Contents:
            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li style={{marginBottom: 0}}><a href="#whatdoesacontainermanagerdo">What does a container manager do?</a></li>
                    <li style={{marginBottom: 0}}><a href="#whatdoesacontainermanagernotdo">What does a container manager <i>not</i> do?</a></li>
                    <li style={{marginBottom: 0}}><a href="#whatwewillbeimplementing">What we will be implementing (for now)</a></li>
                    <li style={{marginBottom: 0}}><a href="#designspec">Design spec</a></li>
                    <li style={{marginBottom: 0}}><a href="#containermanagerbusinesslogicdetails">Container manager business logic details</a></li>
                    <li style={{marginBottom: 0}}><a href="#survivingrestarts">Surviving restarts</a></li>
                    <li style={{marginBottom: 0}}><a href="#letsseeitinaction">Let’s see it in action</a></li>
                </ul>
            </div>

            Find the code <a target="_blank" href="https://github.com/willdeuschle/cruise">here</a>.
        </p>

        <a name="whatdoesacontainermanagerdo"><h2>What does a container manager do?</h2></a>
        <p>
            A container manager is responsible for a subset of the functionality required to run a linux container. Typically, this functionality includes:
            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li>pulling images, preparing a container filesystem and bundle</li>
                    <li>creating and starting containers, stopping and deleting containers, and retrieving status updates from containers</li>
                    <li>attaching to a running container, executing another process in a container, extracting logs from it, and collecting its exit status</li>
                    <li>surviving restarts without impairing or losing track of running containers</li>
                    <li>exposing functionality to an end-user via a CLI/RPC interface</li>
                </ul>
            </div>
            Examples of existing container managers include containerd, dockerd (which as far as container managers go, is just some branding on top of containerd), cri-o, and rkt. Most serious container managers implement the kubernetes’ <a target="_blank" href="https://kubernetes.io/blog/2016/12/container-runtime-interface-cri-in-kubernetes/">container runtime interface</a>, which is a standardizing spec for container managers. Note that the term “container runtime” and “container manager” is used somewhat ambiguously and interchangeably - I will continue referring to them as container managers.
        </p>

        <a name="whatdoesacontainermanagernotdo"><h2>What does a container manager <i>not</i> do?</h2></a>
        <p>
            Container managers themselves need to rely on other pieces of software (both above and below them in the stack) to provide their desired functionality.
            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li>A container manager is not responsible for setting up the linux primitives like cgroups and namespaces that actually define a linux container. That work is left to what we will call a container runtime, like runc. Container managers invoke container runtimes like runc to accomplish these “lower level” tasks.</li>
                    <li>A container manager is typically not directly responsible for providing the ability to attach to a running container, execute another process in a container, extract logs from it, or collect its exit status. This is usually left to what’s known as a container runtime shim, which lives for the lifetime of the container process itself. This is necessary to achieve one of the expectations stated above: container managers should be able to survive restarts without affecting their managed containers.</li>
                    <li>A container manager can (but usually does not) provide controller-like functionality for its containers. These use-cases will typically be accomplished by software higher up the stack that uses the API exposed by a container manager. For example, it may be desirable to restart containers that exit. A container manager does not need to provide this functionality, but another piece of software can use the container manager to implement this (kubernetes’ kubelet does this, for example).</li>
                </ul>
            </div>
        </p>

        <a name="whatwewillbeimplementing"><h2>What we will be implementing (for now)</h2></a>
        <p>
            For the initial implementation, we will implement the following functionality:
            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li>create, start, stop, delete, list, and get status for containers</li>
                    <li>survive container manager restarts gracefully</li>
                    <li>expose functionality to an end-use via a CLI</li>
                </ul>
            </div>
            Notably, we will not be implementing the ability to attach/exec/log/or collect exit statuses of the containers we manage, as this will require a container runtime shim (which we will implement later). Additionally, we will not be implementing image pulling/storage/prep at this time - the prepared filesystem will simply be accepted as input to the container manager. We may implement this at some point in the future.
        </p>

        <a name="designspec"><h2>Design spec</h2></a>
        <b style={{fontSize: 18 + 'px'}}>High-level</b>
        <p>
            As a user, I interact with my container manager by telling it to do something like “create a busybox container” or “retrieve the status of my nginx container”. A typical interaction might look like the following:

            <div data-section-style='11' class='tall'><img src='https://quip.com/blob/cBTAAAbA9qM/Usas35XZkNzjd9hUztZlsA' id='cBTACAscOWe' alt='' width='800' height='943'></img></div>

            This means our container manager needs both server and client components. More specifically, the container manager itself is a daemon (the server component) that exposes its API to users like me via a CLI (the client component).<br/><br/>

            The client and server are going to need some means of communicating locally, and we’ll also need to settle on a communication protocol for them to speak.<br/><br/>

            We can perform the local communication between client and server over a Unix domain socket or a localhost TCP socket. For the communication protocol, a natural choice is gRPC (via HTTP/2) because most languages have good support for client/server gRPC bindings, and the CRI interface is defined in terms of gRPC bindings. Alternatively, we could use something like REST.<br/><br/>

            For our implementation, we’ll go with gRPC over a TCP socket bound to localhost at an agreed upon port. The current known requirements for our container manager are:

            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li>client (CLI)</li>
                    <li>server (daemon)</li>
                    <li>communication using gRPC over a TCP socket</li>
                </ul>
            </div>

            This forms the high-level framework in which our container manager will be operating. Let’s next consider the different functional components of a container manager.
        </p>

        <b style={{fontSize: 18 + 'px'}}>Container manager components</b>
        <p>
            The container manager needs to prepare container bundles, maintain persistent container state to survive restarts, communicate with the low-level container runtime (runc or a runtime shim), and service client requests. Note that most container managers also provide image pulling/caching/unpacking, but we will not be implementing this piece. Based on these requirements, we can roughly break our container manager down into the following components:
            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li><i>server</i>: shepherd incoming client requests to a handler. Manages all request-response transportation.</li>
                    <li><i>handler</i>: the glue. It receives client requests and orchestrates interactions between the in-memory store, runtime manager, and persistent store to respond to those requests. It’s so central that we actually name this component <code>ContainerManager</code> in our source code.</li>
                    <li><i>persistent store</i>: handles data on disk. This includes preparing container bundles, saving state to survive restarts, and reloading relevant data on restarts. Named <code>ContainerStore</code>.</li>
                    <li><i>runtime manager</i>: interacts with the low-level container runtime. This includes creating/starting/&lt;insert verb here&gt;ing containers, updating container status, etc. Named <code>ContainerRuntime</code>.</li>
                    <li><i>in-memory store</i>: maintains container state in-memory. This allows us to efficiently service client requests. Named <code>ContainerMap</code>.</li>
                </ul>
            </div>

            Here is a diagram of the component breakdown:

            <div data-section-style='11' class='tall'><img src='https://quip.com/blob/cBTAAAbA9qM/bn99qkt6c6ADwqHZXwjCrQ' id='cBTACAjr8IH' alt='' width='800' height='951'></img></div>

            Now that we can visualize the breakdown of responsibilities in our container manager, let’s consider the business logic details.
        </p>

        <a name="containermanagerbusinesslogicdetails"><h2>Container manager business logic details</h2></a>
        <p>
            We’ve established that a container manager needs to prepare container bundles, maintain persistent and in-memory state, restart gracefully, and leverage the low-level container runtime. To zoom in on the details and understand how these overlapping responsibilities are fulfilled, let’s explore the logic behind each API call:

            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li><code>container create</code></li>
                    <li><code>container start</code></li>
                    <li><code>container stop</code></li>
                    <li><code>container get</code></li>
                    <li><code>container list</code></li>
                    <li><code>container delete</code></li>
                </ul>
            </div>
        </p>

        <b style={{fontSize: 18 + 'px'}}>Container create</b>
        <p>
            Here is an example container create command from the client’s perspective:

            <blockquote style={{borderLeft: "4px solid #a6a6a6", paddingLeft: 10 + 'px', marginInlineStart: 0 + 'px', margin: 12 + 'px'}}><i><span style={{color: "#000000"}} textcolor="#000000">vagrant@vagrant:~$ bin/client container create my_container --rootfs=~/tmp/rootfs/&nbsp;echo Hello&nbsp;World<br/></span></i></blockquote>

            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li><code>bin/client container create</code> → invoke the client executable and specify that we’re performing a container creation</li>
                    <li><code>my_container</code> → the container name</li>
                    <li><code>--rootfs=~/tmp/rootfs</code> → specify the container’s root filesystem</li>
                    <li><code>echo</code> → the command to execute in our container</li>
                    <li><code>Hello World</code> → the args provided to our command</li>
                </ul>
            </div>

            After processing this request, the response from the create container invocation is:

            <blockquote style={{borderLeft: "4px solid #a6a6a6", paddingLeft: 10 + 'px', marginInlineStart: 0 + 'px', margin: 12 + 'px'}}><i><span style={{color: "#000000"}} textcolor="#000000">created: 6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2<br/></span></i></blockquote>

            We’re given back the container ID <code>6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2</code>. We can use this ID in future interactions with the container manager.

            As input, the container manager accepts a container name, root filesystem, command and arguments. It processes the request, and as output it produces a container ID. Under the hood, here’s what the container manager needed to do:

            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li>Generate a random ID for the container. From our example, this is <code>6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2</code></li>
                    <li>Create the container directory. This will house the container root filesystem, container specification file, and serialized container state. It will live at <code>lib_root/containers/6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2</code></li>
                    <li>Create the container bundle inside the container directory. The container bundle is a <a target="_blank" href="https://github.com/opencontainers/runtime-spec/blob/master/bundle.md">format defined by the Open Container Initiative</a> that includes all the information needed to load and run a container. It will live at <code>lib_root/containers/6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2/bundle</code>. Creating the container bundle requires us to:</li>
                        <ul>
                            <li>Copy the provided container root filesystem to the container bundle directory. This will live at <code>lib_root/containers/6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2/bundle/rootfs</code></li>
                            <li>Create the container spec file (a JSON file) in the container bundle directory, according to the OCI spec. This will live at <code>lib_root/containers/6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2/bundle/config.json</code>. Update the spec file with provided command <code>echo</code> and arguments <code>Hello World</code></li>
                        </ul>
                    <li>Now that our container bundle is prepared, execute the low-level container runtime (runc) container create command. We need to handle errors (rolling back any of our changes on failure), persist container state to disk/memory, and report the created container ID to the user</li>
                </ul>
            </div>

            <a target="_blank" href="https://github.com/willdeuschle/cruise/blob/master/src/container_manager/mod.rs#L216">Here is the code</a> that the container manager runs to handle a <code>create</code> request:<br/>

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.create_container.nodes[0].html,
              }}
            ></div><br/>
            
            Our container is now in a <code>Created</code> state, which means it’s ready to be run!
        </p>

        <b style={{fontSize: 18 + 'px'}}>Container start</b>
        <p>
            Once our container is in a <code>Created</code> state, it is ready to be started. The start command looks as follows from the client’s perspective:

            <blockquote style={{borderLeft: "4px solid #a6a6a6", paddingLeft: 10 + 'px', marginInlineStart: 0 + 'px', margin: 12 + 'px'}}><i><span style={{color: "#000000"}} textcolor="#000000">vagrant@vagrant:~$ bin/client container start 6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2<br/></span></i></blockquote>

            and the response will be:

            <blockquote style={{borderLeft: "4px solid #a6a6a6", paddingLeft: 10 + 'px', marginInlineStart: 0 + 'px', margin: 12 + 'px'}}><i><span style={{color: "#000000"}} textcolor="#000000">started: 6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2<br/></span></i></blockquote>

            As input, the container manager needed a container ID that is in a created state, and after handling the request, it output that container ID back to us. Under the hood, the container manager needs to do the following things when it receives a start request:

            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li>Verify that the container exists and is in a <code>Created</code> state.</li>
                    <li>Invoke the low-level container runtime to start the container. This means our desired process will begin.</li>
					<li>Update the container state in memory (we now have a container start time and the new status should be <code>Running</code>) and persist this state to disk.</li>
                </ul>
            </div>

            <a target="_blank" href="https://github.com/willdeuschle/cruise/blob/master/src/container_manager/mod.rs#L311">Here is the code</a> that the container manager runs to handle a <code>start</code> request:<br/>

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.start_container.nodes[0].html,
              }}
            ></div><br/>

			Our container is now in a <code>Running</code> state. We can now <code>get</code> the container from our container manager to follow its status, or <code>stop</code> the container. Let’s look at the <code>get</code> command next, since we need to explore how we handle the state of containers that exit on their own.
        </p>

        <b style={{fontSize: 18 + 'px'}}>Container get (and list)</b>
        <p>
            The <code>get</code> command retrieves the status of a container, whether it’s in a <code>Created</code>, <code>Running</code>, or <code>Stopped</code> state. Directly after creating the container, the <code>get</code> request looks as follows from the client’s perspective:

            <blockquote style={{borderLeft: "4px solid #a6a6a6", paddingLeft: 10 + 'px', marginInlineStart: 0 + 'px', margin: 12 + 'px'}}><i><span style={{color: "#000000"}} textcolor="#000000">vagrant@vagrant:~$ bin/client container get 6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2<br/></span></i></blockquote>

            and the response will be:

            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li><i>ID</i>: 6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2</li>
                    <li><i>NAME</i>: my_container</li>
                    <li><i>STATUS</i>: Created</li>
                    <li><i>CREATED</i>: 2020-08-19T14:03:23.334750031+00:00</li>
                    <li><i>STARTED_AT</i>: Not started yet.</li>
                    <li><i>COMMAND</i>: echo</li>
                    <li><i>ARGS</i>: Hello,World</li>
                </ul>
            </div>

            After starting a container, it’s possible that the container will exit on its own (either successfully or due to an internal error). In our example container, we’re executing the command <code>echo Hello World</code> which we should expect to exit almost immediately. This means that after starting the container, our next call to <code>get</code> should recognize that the container has stopped, updating the status in the process. For example:

            <blockquote style={{borderLeft: "4px solid #a6a6a6", paddingLeft: 10 + 'px', marginInlineStart: 0 + 'px', margin: 12 + 'px'}}><i><span style={{color: "#000000"}} textcolor="#000000">vagrant@vagrant:~$ cruise_client container start 6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2 && sleep 2<br/></span></i></blockquote>
            <blockquote style={{borderLeft: "4px solid #a6a6a6", paddingLeft: 10 + 'px', marginInlineStart: 0 + 'px', margin: 12 + 'px'}}><i><span style={{color: "#000000"}} textcolor="#000000">vagrant@vagrant:~$ cruise_client container get 6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2<br/></span></i></blockquote>

            will inform us that the status is now <code>Stopped</code>. Under the hood, this requires the container manager to synchronize itself with the low-level container runtime to determine the current status of the container before reporting it back to the user. Note that a container runtime shim often handles this synchronization on an event driven basis for the container manager. The shim is also able to provide more rich information such as a precise finishing time and the exit code of the container process. Our container manager is going to be comparatively simple, and in the future will integrate with a container runtime shim to provide a more full picture.

            To handle a <code>get</code> request, our container manager does the following:

            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li>It cheaply verifies that the container is known to exist by checking its in-memory store.</li>
                    <li>After verifying the container’s existence, the container manager needs to reconcile the true state of the world with the state of the world that it holds in memory and on disk. In the time that’s passed between starting the container and the arrival of the <code>get</code> request, the container manager does not know if the container has exited. To perform this reconciliation, the container manager retrieves the current state of the container from the low-level container runtime, and updates its in-memory and on-disk stores.</li>
                    <li>The container manager is now confident that it has the true state of the world, and returns that to the client.</li>
                </ul>
            </div>

            <a target="_blank" href="https://github.com/willdeuschle/cruise/blob/master/src/container_manager/mod.rs#L391">Here is the code</a> that the container manager runs to handle a <code>get</code> request:<br/>

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.get_container.nodes[0].html,
              }}
            ></div><br/>

        	See that the <code>list</code> command is just a generalized form of a container <code>get</code>. Instead of synchronizing and returning a specific container ID, the container manager goes through its entire in-memory store, refreshing the state of all containers, and returning those results back to the client in their entirety.
        </p>

        <b style={{fontSize: 18 + 'px'}}>Container stop</b>
        <p>
			Once our container is in a <code>Running</code> state, if we don’t wish for it to continue running (and it has not yet exited), we can choose to <code>stop</code> the container. Note that stopping and deleting a container are distinct operations, and <code>delete</code> can only proceed if a container is in a <code>Stopped</code> state (or if it has not yet been started). The <code>stop</code> command looks as follows from the client’s perspective:

            <blockquote style={{borderLeft: "4px solid #a6a6a6", paddingLeft: 10 + 'px', marginInlineStart: 0 + 'px', margin: 12 + 'px'}}><i><span style={{color: "#000000"}} textcolor="#000000">vagrant@vagrant:~$ bin/client container stop 6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2<br/></span></i></blockquote>

            and the response will be:
    
            <blockquote style={{borderLeft: "4px solid #a6a6a6", paddingLeft: 10 + 'px', marginInlineStart: 0 + 'px', margin: 12 + 'px'}}><i><span style={{color: "#000000"}} textcolor="#000000">stopped: 6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2<br/></span></i></blockquote>

            If we were to <code>get</code> this container, it would now be reporting a <code>Stopped</code> status. Under the hood, our container manager does the following in response to a <code>stop</code> request:

            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li>It verifies that the container exists and is in a <code>Running</code> state via its in-memory store.</li>
                    <li>After verifying existence and <code>Running</code> status, the container manager instructs the low-level container runtime to kill the container process (by sending it a <code>SIGTERM</code> or <code>SIGKILL</code>).</li>
                    <li>The container manager updates the container status to <code>Stopped</code>, storing the changes in-memory and persisting to disk as well.</li>
                </ul>
            </div>

            <a target="_blank" href="https://github.com/willdeuschle/cruise/blob/master/src/container_manager/mod.rs#L337">Here is the code</a> that the container manager runs to handle a <code>stop</code> request:<br/>

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.stop_container.nodes[0].html,
              }}
            ></div><br/>

			At this point, the container process is no longer running and the container is in a <code>Stopped</code> state. The container metadata still exists in our container manager and the low-level container runtime, and the container filesystem is still present on disk if we wished to inspect it. Our container is now eligible for deletion, which will free those resources.
		</p>

        <b style={{fontSize: 18 + 'px'}}>Container delete</b>
        <p>
			Once a container is in a <code>Stopped</code> state, it is eligible for deletion, which will free the resources it occupies even after the container process terminates. The delete command, unsurprisingly, looks like the following from the client’s perspective:

            <blockquote style={{borderLeft: "4px solid #a6a6a6", paddingLeft: 10 + 'px', marginInlineStart: 0 + 'px', margin: 12 + 'px'}}><i><span style={{color: "#000000"}} textcolor="#000000">vagrant@vagrant:~$ bin/client container delete 6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2<br/></span></i></blockquote>

			and the response will be:

            <blockquote style={{borderLeft: "4px solid #a6a6a6", paddingLeft: 10 + 'px', marginInlineStart: 0 + 'px', margin: 12 + 'px'}}><i><span style={{color: "#000000"}} textcolor="#000000">deleted: 6bce9dc1-0a03-4bb7-86f5-dd75fdae7fa2<br/></span></i></blockquote>

			At this point, the container and the resources it occupies no longer exist. Calls to <code>get</code> will fail, and calls to <code>list</code> will not find it. Under the hood, the container manager does the following in response to a <code>delete</code> call:

            <div style={{margin: 12 + 'px'}}>
                <ul>
					<li>The container manager verifies that the container exists and is in a <code>Stopped</code> state (it is also valid to delete containers that are not yet started, meaning they are in a <code>Created</code> state).</li>
					<li>After verifying existence and <code>Stopped</code> or <code>Created</code> status, the container manager tells the low-level container runtime to delete the container, which allows it to clean up its state related to this container.</li>
					<li>Finally, the container manager removes its in-memory copy of the container and purges all container state from disk (both the container manager’s bookkeeping data and the container filesystem itself).</li>
                </ul>
            </div>

            <a target="_blank" href="https://github.com/willdeuschle/cruise/blob/master/src/container_manager/mod.rs#L216">Here is the code</a> that the container manager runs to handle a <code>delete</code> request:<br/>

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.delete_container.nodes[0].html,
              }}
            ></div><br/>

			At this point, no trace of the container exists.
		</p>

        <a name="survivingrestarts"><h2>Surviving restarts</h2></a>
        <p>
            Until now, we’ve examined how a container manager carries out its explicit responsibilities of manipulating containers in response to client requests. As we stated earlier, another requirement is that the container manager be able to survive restarts without impacting the containers it manages. This allows us to upgrade the container manager on the fly, and more importantly, it decouples the stability of the containers from the stability of the container manager. This means that if the container manager were to crash for some reason, our existing containers would continue running happily.

            In discussing the business logic behind <code>create</code>, <code>start</code>, <code>stop</code>, <code>get</code>, <code>list</code>, and <code>delete</code>, we’ve already encountered some of the required groundwork that allows our container manager to survive restarts. Specifically, we persist container state to disk at every possible opportunity. This is important because that on-disk state is used to reconstruct the state of the world at container manager startup time. In fact, every time the container manager starts, it will attempt to reconstruct the state of the world, even the first time it starts on a machine (in which case the reconstruction is a no-op).

            It’s important to recognize we have no guarantee that the state of the world at restart is the same as the state of the world when the container manager last stopped. This means that in addition to reloading the state from disk, the container manager needs to resync the state of every container it knew about prior to stopping. The goals of the restart are as follows:

            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li>Find every container the container manager knew about when it was last running.</li>
                    <li>Resync the state of those previously known containers.</li>
                    <li>Save in-memory and persist to disk the updated state of those containers.</li>
                    <li>Purge any out-of-date state on disk.</li>
                </ul>
            </div>

            Fortunately, while the container manager does need to resync the state of any containers it knew about previously, it does not need to “find” any new containers that may have been created while it was not running because no new containers could have been created while the container manager was stopped.

            Under the hood, the container manager runs the following reload routine every time it starts up (even if this is in fact the first time it starts up):

            <div style={{margin: 12 + 'px'}}>
                <ul>
                    <li>Read all container state files from disk at a known location. These are the files that we write on container creation and update throughout the lifecycle of the container.</li>
                    <ul>
                        <li>If any of these state files fails to be parsed, we abandon the container and remove its state.</li>
                    </ul>
                    <li>Add the container to the in-memory store.</li>
                    <li>Sync the container state with the low-level container runtime, persisting it in memory and on disk.</li>
                </ul>
            </div>

            <a target="_blank" href="https://github.com/willdeuschle/cruise/blob/master/src/container_manager/mod.rs#L157">Here is the container manager’s reload code</a>, run every time it starts:<br/>

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.container_manager_reload.nodes[0].html,
              }}
            ></div><br/>

            At this point, the container manager is up-to-date with the current state of the world and can proceed with normal operations.
        </p>

        <a name="letsseeitinaction"><h2>Let’s see it in action</h2></a>
        <p>
            I ran this in a Vagrant box with Ubuntu 18.04. Because our container manager shells out to runc under the hood, it needed to be a linux distro to work properly.

			Here’s what I put in my <code>Vagrantfile</code>:
			
            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.vagrantfile.nodes[0].html,
              }}
            ></div><br/>

            I then set up the environment as follows:

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.environment_setup.nodes[0].html,
              }}
            ></div><br/>

            Now I can run the daemon in my Vagrant box:

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.daemon_run.nodes[0].html,
              }}
            ></div><br/>

            Now it’s time to interact with the daemon using the client. From a new shell, start another session in your Vagrant box:

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.client_run.nodes[0].html,
              }}
            ></div><br/>

            At this point, if we switch back to the daemon shell, we should see <code>hi</code> output over there from our new container!

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.daemon_hi.nodes[0].html,
              }}
            ></div><br/>

            If we switch back to our client shell, we can interact some more with our container:

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.client_status_one.nodes[0].html,
              }}
            ></div><br/>
            
            For the next minute, we will find that our container is in a <code>Running</code> state (while it sleeps). After a minute, in our daemon shell, we will see <code>bye</code> output from our container:

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.daemon_bye.nodes[0].html,
              }}
            ></div><br/>

            and the container will then transition into a <code>Stopped</code> state:

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.client_status_two.nodes[0].html,
              }}
            ></div><br/>

            We can now clean up the container:

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.container_cleanup.nodes[0].html,
              }}
            ></div><br/>

            And if we list our containers, we will see our container no longer exists:

            <div
              className="code-container"
              dangerouslySetInnerHTML={{
                __html: data.container_list.nodes[0].html,
              }}
            ></div><br/>

            And that’s all for now! We have a minimal container manager. Next up, we will implement a container runtime shim to inject some more functionality and interactivity into our nascent container ecosystem.
        </p>

      </BlogLayout>
    )
}

export default SecondPage
